import React from "react";
const LadyBug = () => {
  return (
    <div className="bug">
      <div className="ladybug"></div>
      <div id="wingleft" className="wing">
        {" "}
      </div>
      <div id="wingright" className="wing">
        {" "}
      </div>
      <div className="eye"> </div>
      <div className="eye2"> </div>
      <div className="eyelid"> </div>
      <div className="eyelid2"> </div>
      <div className="eyespot"> </div>
      <div className="eyespot2"> </div>
      <div className="spot"> </div>
      <div className="spot2"> </div>
      <div className="spot3"> </div>
      <div className="spot4"> </div>
      <div className="spot5"> </div>
      <div className="spot6"> </div>
      <div className="leg"> </div>
      <div className="leg2"> </div>
      <div className="leg3"> </div>
    </div>
  );
};
export default LadyBug;
