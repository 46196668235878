import React from "react";

const Project = (props) => {
  return (
    <div className="projects">
      <header>
        <h2 className="pheader">
          <a style={{ color: "white" }} href={props.url}>
            {props.header}
          </a>
        </h2>
      </header>
      <a className="thumbnail" href={props.url}>
        {" "}
        <img className="thumbimg" src={props.thumb} />{" "}
      </a>
      <footer class="dfooter">
        {props.tech.reduce((s, l) => s + ", " + l)}
      </footer>
    </div>
  );
};
export default Project;
