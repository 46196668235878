import React from "react";

const Blog = () => {
  return (
    <div id="blog">
      <a href="#projects">
        <img
          className="scrollleft"
          alt="scroll left"
          src="https://cdn-icons-png.flaticon.com/512/25/25283.png"
        />
      </a>
      <a href="#resume">
        <img
          className="scrollright"
          alt="scroll right"
          src="https://cdn-icons-png.flaticon.com/512/25/25283.png"
        />
      </a>
      <div id="bb">Coming Soon!</div>
    </div>
  );
};

export default Blog;
