import React from "react";
import { render } from "react-dom";
import Main from "./pages/main.js";
import Research from "./pages/research.js";
import Programming from "./pages/cs.js";
import Other from "./pages/misc.js";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

const App = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={Main} />
          <Route exact path="/research" component={Research} />
          <Route exact path="/cs" component={Programming} />
          <Route exact path="/misc" component={Other} />
        </Switch>
      </Router>
    </>
  );
};

render(<App />, document.getElementById("root"));
