import React from "react";

const Resume = () => {
  return (
    <div id="resume">
      {" "}
      <a href="#blog">
        <img
          className="scrollleft"
          alt="scroll left"
          src="https://cdn-icons-png.flaticon.com/512/25/25283.png"
        />
      </a>
      <a href="#contact">
        <img
          className="scrollright"
          alt="scroll right"
          src="https://cdn-icons-png.flaticon.com/512/25/25283.png"
        />
      </a>
      <iframe
        id="pdf"
        src="https://drive.google.com/file/d/13Cq3K3VdShpPN6oX6NdtgW6nXuMfCmP1/preview"
        allow="autoplay"
      ></iframe>
    </div>
  );
};

export default Resume;
