import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";

const Main = () => {
  return (
    <>
      <header>
        <h1 className="main">Anastasiia Tokhtamysh</h1>
        <h1 className="inverted">Анастасія Тохтамиш</h1>
      </header>
      <main>
        <Link id="research" className="window" to="/research">
          <div>Research</div>
        </Link>
        <Link id="cs" className="window" to="/cs">
          <div>Programming</div>
        </Link>
        <Link id="other" className="window" to="/misc">
          <div>Other</div>
        </Link>
      </main>
      <footer>
        <Link id="mainLink1" to="/research">
          <div>Research</div>
        </Link>
        <Link id="mainLink2" to="/cs">
          <div>Programming</div>
        </Link>
        <Link id="mainLink3" to="/misc">
          <div>Other</div>
        </Link>
      </footer>
    </>
  );
};

export default Main;
