import React from "react";
import NavBar from "./cs/NavBar.js";
import MainCS from "./cs/MainCS.js";

const Programming = () => {
  return (
    <>
      <NavBar />
      <MainCS />
    </>
  );
};

export default Programming;
