import React, { useState } from "react";
import LadyBug from "./LadyBug";

const Home = () => {
  const [moth, setMoth] = useState(0);

  return (
    <div id="home">
      <h1 className="firstLine" style={{ fontSize: "10vw" }}>
        Welcome!
      </h1>
      <div className="secondLine" style={{ fontSize: "4vw" }}>
        My name is Anastasiia and I enjoy{" "}
      </div>{" "}
      <div className="thirdLine" style={{ fontSize: "4vw" }}>
        writing{" "}
        <button
          className={moth ? "neon" : ""}
          id="moth"
          onClick={() => setMoth(!moth)}
        >
          code
        </button>{" "}
        that works efficiently.
      </div>
      <LadyBug />
      <a
        href="https://www.nationalgeographic.org/thisday/sep9/worlds-first-computer-bug/"
        className="sun"
        target="_blank"
      ></a>
      <div className={moth ? "moth" : "hidden"}>
        <div className="body"></div>
        <div className="wings2"></div>
        <div className="wings"></div>
      </div>
      <div className={moth ? "ladder" : "hidden"}></div>
      <a href="#about">
        <img
          className="scrollright"
          alt="scroll left"
          src="https://cdn-icons-png.flaticon.com/512/25/25283.png"
        />
      </a>
    </div>
  );
};

export default Home;
