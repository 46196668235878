import React from "react";
import emailjs from "emailjs-com";
import { init } from "emailjs-com";

const Contacts = () => {
  function sendEmail(e) {
    e.preventDefault();
    // init("user_XkjBTaDQ465p0qvz9TkLZ");
    emailjs
      .sendForm(
        "service_5wbdsoo",
        "template_k5p7e3r",
        e.target,
        "user_XkjBTaDQ465p0qvz9TkLZ"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  return (
    <div id="contact">
      {" "}
      <a href="#resume">
        <img
          className="scrollleft"
          alt="scroll left"
          src="https://cdn-icons-png.flaticon.com/512/25/25283.png"
        />
      </a>
      <form id="contactform" onSubmit={sendEmail}>
        <input type="hidden" name="contact_number" />
        <label>Name:</label>
        <br></br>
        <input className="contactInput" type="text" name="user_name" />
        <br></br>
        <label>Email:</label>
        <br></br>
        <input className="contactInput" type="email" name="user_email" />
        <br></br>
        <label>Message:</label>
        <br></br>
        <textarea id="message" className="contactInput" name="message" />
        <br></br>
        <input id="contactsubmit" type="submit" value="Send" />
      </form>
    </div>
  );
};

export default Contacts;
