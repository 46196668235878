import React from "react";
import Project from "./Project.js";

const props = [
  {
    header: "GitFacts",
    url: "https://gitfacts.dev/",
    thumb:
      "https://github.com/Stemonitis/GitFacts/raw/master/src/logo?raw=true",
    tech: ["React", "CSS", "D3", "GraphQL", "Apollo"],
  },
  {
    header: "Hyperion Python lib for multispectral satellite images",
    url: "https://github.com/Stemonitis/Hyperion",
    thumb:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fdirectory.eoportal.org%2Fdocuments%2F163813%2F2593013%2FEO1_Auto3F.jpeg&f=1&nofb=1",
    tech: ["Python", "numpy", "scipy", "pyhdf"],
  },
  {
    header: "ChatBot",
    url: "https://gitfacts.dev/",
    thumb:
      "https://i0.wp.com/www.healthfitnessrevolution.com/wp-content/uploads/2015/11/coming-soon.gif?fit=2048%2C1365",
    tech: ["React", "CSS", "D3", "GraphQL", "Apollo"],
  },
  {
    header: "API",
    url: "https://gitfacts.dev/",
    thumb:
      "https://i0.wp.com/www.healthfitnessrevolution.com/wp-content/uploads/2015/11/coming-soon.gif?fit=2048%2C1365",
    tech: ["Other"],
  },
  {
    header: "D3 Map",
    url: "https://codepen.io/stemonitis/pen/ExYXzvY",
    thumb: "https://i.ibb.co/2gY8cFj/Screenshot-20210727-155238.png",
    tech: ["CSS", "D3"],
  },

  {
    header: "New Album landing page",
    url: "https://alorahtest.tumblr.com/",
    thumb: "https://i.ibb.co/W2PSd17/Screenshot-20210727-155700.png",
    tech: ["CSS"],
  },
];
const Projects = () => {
  return (
    <div id="projects">
      <a href="#about">
        <img
          className="scrollleft"
          alt="scroll left"
          src="https://cdn-icons-png.flaticon.com/512/25/25283.png"
        />
      </a>
      <a href="#blog">
        <img
          className="scrollright"
          alt="scroll right"
          src="https://cdn-icons-png.flaticon.com/512/25/25283.png"
        />
      </a>
      <div id="projectsContainer">
        {props.map((o, i) => {
          return (
            <Project
              key={i}
              header={o.header}
              url={o.url}
              thumb={o.thumb}
              tech={o.tech}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Projects;
