import React from "react";

const NavBar = () => {
  return (
    <nav id="csnav">
      <a href="#home" id="mfirst" className="mountain">
        Home
      </a>
      <a href="#about" id="msecond" className="mountain">
        About
      </a>
      <a href="#projects" id="mthird" className="mountain">
        Projects{" "}
      </a>
      <a href="#blog" id="mforth" className="mountain">
        Blog{" "}
      </a>
      <a href="#resume" id="mfifth" className="mountain">
        Resume{" "}
      </a>
      <a href="#contact" id="msixth" className="mountain">
        Contact{" "}
      </a>
    </nav>
  );
};

export default NavBar;
