import React, { useState } from "react";
const About = () => {
  const [toggle, setT] = useState(false);
  return (
    <div id="about">
      <a href="#home">
        <img
          className="scrollleft"
          alt="scroll left"
          src="https://cdn-icons-png.flaticon.com/512/25/25283.png"
        />
      </a>
      <a href="#projects">
        <img
          className="scrollright"
          alt="scroll right"
          src="https://cdn-icons-png.flaticon.com/512/25/25283.png"
        />
      </a>
      <div className="aboutMe" onClick={(e) => setT(!toggle)}>
        {toggle ? (
          <div id="skills">
            <h2> Technical skills</h2>
            <h3>Strong: </h3> Javascript(ES6+), React.js, React Hooks, D3.js,
            CSS, HTML/HTML5, Python2, Python3, Numpy, SciPy, Seaborn, Linux,
            Bash, Apollo GraphQL, Git/GitHub, GitHub API.
            <br></br>
            <h3>Experienced:</h3> Redux, Node and Express, R, Pandas, Bash,
            Bootstrap, relational and non- relational databases, NGINX, Jest,
            Cypress, Typescript.
          </div>
        ) : (
          <div id="columns">
            <p>
              I am a software developer specializing in web programming and my
              toolset consists of JavaScript, Python, React, GraphQL, etc. My
              main goals are to deliver a seamless user experience, design
              efficient reusable code, and create new cool ways to optimize my
              programs.
              <br></br>
            </p>
            <p>
              For most of my life, I have worked in academia (biology/biophysics
              /neuroscience) and I used to treat programming more as a
              necessity, than an opportunity to build something. But everything
              changed after I designed my first web application! Being a web
              developer is an inspiring opportunity to deliver software that is
              instantly accessible to any user. And the user often does not even
              realize the complex logic behind what appears to be a simple
              webpage.{" "}
            </p>
            <p>
              {" "}
              But programming to me is so much more than just delivering a
              product. In the last couple of years as I learned more about
              logic, algorithms, data structures, and design patterns I realized
              that a lot of the most compelling scientific questions are posited
              in the field of software engineering. And this motivates me even
              further to discover new design patterns and to learn how things
              work under the hood. If you have any questions about my work or
              experience or if you want to connect and collaborate, please do
              not hesitate to contact me!{" "}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default About;
