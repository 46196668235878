import React from "react";
import Home from "../cs/Home.js";
import About from "../cs/About.js";
import Projects from "../cs/Projects.js";
import Blog from "../cs/Blog.js";
import Resume from "../cs/Resume.js";
import Contact from "../cs/Contact.js";
import useHorizontal from "@oberon-amsterdam/horizontal/hook";

const MainCS = () => {
  const { innerWidth: width, innerHeight: height } = window;
  innerWidth > 420 ? useHorizontal() : null;
  return (
    <div id="programming-wrapper">
      <Home />
      <About />
      <Projects />
      <Blog />
      <Resume />
      <Contact />
    </div>
  );
};
export default MainCS;
